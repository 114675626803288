.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(50vh - 200px);
}

.FormContainer {
  width: 90%;
  max-width: 400px;
}
