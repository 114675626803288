input[type='color'] {
  width: 45px;
}

.was-validated input[type='color'] {
  width: 70px;
}

table td {
  vertical-align: middle!important;
}