.ColorPickerContainer {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  position: relative;
}

.ColorPickerRemoveButton {
  padding: 1px 3px;
  font-size: 10px;
  line-height: 1;
  position: absolute;
  top: -7px;
  right: -4px;
}

.CategoricalColormapContainer {
  max-height: 150px;
  overflow: hidden auto;
}