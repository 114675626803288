:root {
  --color-white: #FFFFFF;
  --color-offwhite: #CCCCCC;
  --color-black: #000000;
  --color-offblack: #0a0a0a;
  --color-gray: #888888;
  --color-darkgray: #262626;;

  --color-button-delete: #F08080;
  --color-button-select: #ADD8E6;
  --color-button-confirm: #90EE90;

  --navbar-height: 56px;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
