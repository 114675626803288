.ColormapContainer {
  height: 35px;
  display: flex;
  align-items: center;
}

.ColormapImage {
  width: 100%;
  height: 20px;
  min-width: 100px;
}
